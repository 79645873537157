

<div class="my-main-thing container">
  <h3>
    Alex's Desk Temperature
  </h3>
  <h1 class="my-temp">
    {{currentTemperature}}
  </h1>
  <div *ngIf="chartsPop" class="my-temp-graph">
    <ngx-charts-line-chart
      [view]="[1000,500]"
      [results]="tempData"
      [animations]="true"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxisLabel]="'Time'"
      [yAxisLabel]="'Temperature (Celsius)'"
      [timeline]="true"
      [autoScale]="true"
      [yScaleMin]="21"
      [showGridLines]="false"
    >
    </ngx-charts-line-chart>
  </div>
</div>


